import React from "react"
import Technology from "./Technology/Technology"

interface TechnologyListProps {
    technologies: string[]
    keyPrefix: string
    isIncreasedSize: boolean
}

export const TechnologyList: React.FC<TechnologyListProps> = ({ technologies, keyPrefix, isIncreasedSize }) => {
    return (
        <>
            {technologies.map((el, i) =>
                <Technology key={`${keyPrefix}${i}`} technology={el} increasedSize={isIncreasedSize} />
            )}
        </>
    )
}