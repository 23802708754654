import { navigate } from "gatsby";
import React from "react";
import * as styles from "./BackButton.module.scss";

const goBack = () => navigate(-1)

const BackButton: React.FC = () => (
    <div onClick={goBack} className={styles.backButton}>
        Go Back
    </div>
)

export default BackButton