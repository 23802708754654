import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import mobilebig from '../../../images/technologiesPage/mobilebig.svg';
import mobilesmall from '../../../images/technologiesPage/mobilesmall.svg';
import StepItem from '../StepItem/StepItem';
import { TechnologyList } from '../TechnologiesList';
import * as styles from './Mobile.module.scss';

const STEPS = [
    'User Requirements analysis',
    'Wireframing',
    'Technical Feasibility Assessment',
    'Prototyping',
    'Responsive User Interface (UI)',
    'Development',
    'Usability and Load Testing',
    'Deployment',
];

const TECHNOLOGIES = [
    'sdk',
    'arc',
    'android',
    'java',
    'kotlin',
    'androidicon',
    'retrofit',
    'firefox',
    'mockito',
    'junit',
    'flower',
    'rest',
];

const Mobile: React.FC = () => {
    const breakpoints = useBreakpoint()

    return (
        <div id="mobile" className={styles.wrapper}>
            <div className={styles.top}>
                <img className={styles.browserClass} src={mobilesmall} alt="mobilesmall" />

                <span className={styles.topTitle}>Mobile Applications</span>
            </div>

            <div className={styles.mid}>
                {!breakpoints.l && (
                    <div className={styles.left}>
                        <img className={styles.monitoringClass} src={mobilebig} alt="mobilebig" />
                    </div>
                )}
                <div className={styles.right}>
                    {STEPS.map((el, i) => {
                        return (
                            <StepItem
                                key={'mwebStep' + i}
                                num={i + 1}
                                name={el}
                                lastItem={i === STEPS.length - 1}
                                className={styles.stepItem}
                            />
                        );
                    })}
                </div>
            </div>
            <div className={styles.bot}>
                <TechnologyList
                    technologies={breakpoints.l ? TECHNOLOGIES.slice(0, 4) : TECHNOLOGIES}
                    keyPrefix="mobileTech"
                    isIncreasedSize={false}
                />
            </div>
        </div>
    );
}

export default Mobile;
