import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import React from 'react';
import * as styles from './Technology.module.scss';

interface TechnologyProps {
    technology: string
    increasedSize: boolean
}

const Technology: React.FC<TechnologyProps> = ({ technology, increasedSize }) => {
    const singleTechnologyClass = classnames(styles.singleTechnology, {
        [styles.increasedSize]: increasedSize,
        [styles.redis]: technology === 'redis',
        [styles.mysql]: technology === 'mysql',
        [styles.react]: technology === 'react',
        [styles.reactweb]: technology === 'reactweb',
        [styles.magnifier]: technology === 'magnifier',
        [styles.d3]: technology === 'd3',
        [styles.rectangle]: technology === 'rectangle',
        [styles.postgresql]: technology === 'postgresql',
        [styles.docker]: technology === 'docker',
        [styles.jenkins]: technology === 'jenkins',
        [styles.jenkins2]: technology === 'jenkins2',
        [styles.azure]: technology === 'azure',
        [styles.aws]: technology === 'aws',
        [styles.etcd]: technology === 'etcd',
        [styles.microsoft]: technology === 'microsoft',
        [styles.kubernetes]: technology === 'kubernetes',
        [styles.kubernetes2]: technology === 'kubernetes2',
        [styles.softlayer]: technology === 'softlayer',
        [styles.zookeeper]: technology === 'zookeeper',
        [styles.greencloud]: technology === 'greencloud',
        [styles.t]: technology === 't',
        [styles.nginx]: technology === 'nginx',
        [styles.openshift]: technology === 'openshift',
        [styles.istio]: technology === 'istio',
        [styles.linkerd]: technology === 'linkerd',
        [styles.git]: technology === 'git',
        [styles.yaml]: technology === 'yaml',
        [styles.vue]: technology === 'vue',
        [styles.compose]: technology === 'compose',
        [styles.marathon]: technology === 'marathon',
        [styles.figma]: technology === 'figma',
        [styles.rubin]: technology === 'rubin',
        [styles.baloon]: technology === 'baloon',
        [styles.proto]: technology === 'proto',
        [styles.ps]: technology === 'ps',
        [styles.stairs]: technology === 'stairs',
        [styles.ae]: technology === 'ae',
        [styles.axure]: technology === 'axure',
        [styles.xd]: technology === 'xd',
        [styles.inIcon]: technology === 'in',
        [styles.angular]: technology === 'angular',
        [styles.ember]: technology === 'ember',
        [styles.vueweb]: technology === 'vueweb',
        [styles.nodejs]: technology === 'nodejs',
        [styles.sdk]: technology === 'sdk',
        [styles.arc]: technology === 'arc',
        [styles.android]: technology === 'android',
        [styles.java]: technology === 'java',
        [styles.kotlin]: technology === 'kotlin',
        [styles.androidicon]: technology === 'androidicon',
        [styles.retrofit]: technology === 'retrofit',
        [styles.firefox]: technology === 'firefox',
        [styles.mockito]: technology === 'mockito',
        [styles.junit]: technology === 'junit',
        [styles.flower]: technology === 'flower',
        [styles.rest]: technology === 'rest',
        [styles.python]: technology === 'python',
        [styles.scikit]: technology === 'scikit',
        [styles.scipy]: technology === 'scipy',
        [styles.mlflow]: technology === 'mlflow',
    });

    return (
        <Tippy
            placement="bottom-end"
            offset={[0, 0]}
            content={technology}
            className={styles.tooltip}
            arrow={false}
        >
            <div className={singleTechnologyClass}></div>
        </Tippy>
    )
};

export default Technology;
