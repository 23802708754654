import React from "react"

export const MachineLearningIcon: React.FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="53"
        height="50"
        fill="none"
        viewBox="0 0 53 50"
    >
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M9.807 11.2l-1.63.303a7.573 7.573 0 00-4.47 12.23L4.742 25l-1.035 1.266a7.573 7.573 0 004.471 12.231l1.629.303.006 1.657a7.57 7.57 0 0011.624 6.367V3.176A7.57 7.57 0 009.813 9.543L9.808 11.2zm13.63-9.042A9.53 9.53 0 0017.383 0a9.57 9.57 0 00-9.57 9.536C3.367 10.361 0 14.26 0 18.946A9.53 9.53 0 002.158 25 9.53 9.53 0 000 31.055c0 4.685 3.367 8.584 7.813 9.409A9.57 9.57 0 0017.383 50c2.297 0 4.405-.81 6.055-2.158V2.158zM29.344 2.158A9.53 9.53 0 0135.398 0a9.57 9.57 0 019.57 9.536c4.447.825 7.813 4.724 7.813 9.41 0 2.296-.81 4.404-2.158 6.054a9.531 9.531 0 012.158 6.055c0 4.685-3.366 8.584-7.812 9.409A9.57 9.57 0 0135.399 50a9.53 9.53 0 01-6.055-2.158V26.953h4.888a4.494 4.494 0 008.627-1.758 4.492 4.492 0 00-8.438-2.148h-5.077V2.158z"
            clipRule="evenodd"
        ></path>
    </svg>
)