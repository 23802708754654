import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import miniweb from '../../../images/technologiesPage/miniweb.svg';
import web from '../../../images/technologiesPage/web.svg';
import StepItem from '../StepItem/StepItem';
import { TechnologyList } from '../TechnologiesList';
import * as styles from './Web.module.scss';

const STEPS = [
    'User Requirements analysis',
    'Technologies choice',
    'Responsive and adaptive UX/UI',
    'Offline first',
    'RIntegration with 3rd party modules',
    'Iterative development and high scalability',
    'Usability and Load Testing',
    'Deployment',
];

const TECHNOLOGIES = ['reactweb', 'angular', 'ember', 'vueweb', 'nodejs'];

const Web: React.FC = () => {
    const breakpoints = useBreakpoint()

    return (
        <div id="web" className={styles.wrapper}>
            <div className={styles.top}>
                <img className={styles.browserClass} src={miniweb} alt="miniweb" />

                <span className={styles.topTitle}>Web Applications</span>
            </div>

            <div className={styles.mid}>
                {!breakpoints.l && (
                    <div className={styles.left}>
                        <img className={styles.monitoringClass} src={web} alt="cloud" />
                    </div>
                )}
                <div className={styles.right}>
                    {STEPS.map((el, i) => {
                        return (
                            <StepItem
                                key={'mwebStep' + i}
                                isOrange={true}
                                num={i + 1}
                                name={el}
                                lastItem={i === STEPS.length - 1}
                            />
                        );
                    })}
                </div>
            </div>
            <div className={styles.bot}>
                <TechnologyList
                    technologies={breakpoints.l ? TECHNOLOGIES.slice(0, 4) : TECHNOLOGIES}
                    keyPrefix="webTech"
                    isIncreasedSize={!breakpoints.l}
                />
            </div>
        </div>
    );
}

export default Web;
