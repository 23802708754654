import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import browser from '../../../images/technologiesPage/browser.svg';
import monitoring from '../../../images/technologiesPage/monitoring.svg';
import technologies from '../../../images/technologiesPage/technologies.svg';
import BackButton from '../../BackButton/BackButton';
import SectionHeader from '../../SectionHeader';
import StepItem from '../StepItem/StepItem';
import { TechnologyList } from '../TechnologiesList';
import * as styles from './Solutions.module.scss';

const STEPS = [
    {
        name: 'Dashboard',
    },
    {
        name: 'Inventory Management',
    },
    {
        name: 'Alarms Monitoring And Root Cause Analysis',
    },
    {
        name: ' Configuration And Provisioning',
    },
    {
        name: 'Performance Monitoring and Threshold Crossing Alerts',
    },
    {
        name: 'Security',
    },
];

const TECHNOLOGIES = ['redis', 'postgresql', 'mysql', 'react', 'magnifier', 'docker', 'd3', 'rectangle'];

const Solutions: React.FC = () => {
    const breakpoints = useBreakpoint()

    return (
        <div id="solutions" className={styles.wrapper}>
            <div className={styles.headers}>
                <SectionHeader main="Technologies" secondary="our expertise" />
                <img className={styles.technologies} src={technologies} alt="Technologies" />
                <BackButton />
            </div>
            <div className={styles.monitoringBlock}>
                <div className={styles.top}>
                    <img className={styles.browserClass} src={browser} alt="browser" />

                    <span className={styles.topTitle}>Monitoring and Control Systems</span>
                </div>

                <div className={styles.mid}>
                    <div className={styles.midLeft}>
                        {STEPS.map((el, i) => {
                            return (
                                <StepItem
                                    key={'solutionstep' + i}
                                    num={i + 1}
                                    name={el.name}
                                    lastItem={i === STEPS.length - 1}
                                />
                            );
                        })}
                    </div>
                    {!breakpoints.l && (
                        <div className={styles.midRight}>
                            <img className={styles.monitoringClass} src={monitoring} alt="browser" />
                        </div>
                    )}
                </div>
                <div className={styles.bot}>
                    <TechnologyList
                        technologies={breakpoints.l ? TECHNOLOGIES.slice(0, 4) : TECHNOLOGIES}
                        keyPrefix="solutionTech"
                        isIncreasedSize={false}
                    />
                </div>
            </div>
            <div className={styles.violetBack}></div>
        </div>
    );
}

export default Solutions;
