import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React from "react";
import StepItem from "../StepItem/StepItem";
import { TechnologyList } from "../TechnologiesList";
import * as styles from "./MachineLearning.module.scss";
import { MachineLearningIcon } from "./MachineLearningIcon";
import { MachineLearningIllustration } from "./MachineLearningIllustration";

const STEPS = [
    'Datasets analysis',
    'ML models researching and experiments',
    'Design an extensible architecture',
    'Development with continuous integration',
    'Historical data prediction',
    'Real-time data prediction',
    'ML models deployment as a service',
    'Integration with 3rd party services',
];

const TECHNOLOGIES = [
    'python',
    'scikit',
    'scipy',
    'mlflow',
    'postgresql'
];

export const MachineLearning: React.FC = () => {
    const breakpoints = useBreakpoint()

    return (
        <div className={styles.container} id="machine-learning">
            <div className={styles.title}>
                <MachineLearningIcon />
                <h1 className={styles.titleText}>Machine Learning</h1>
            </div>
            <div className={styles.mainContent}>
                {!breakpoints.l &&
                    <MachineLearningIllustration className={styles.illustration} />
                }
                <div>
                    {STEPS.map((el, i) => {
                        return (
                            <StepItem
                                key={'machineLearningStep' + i}
                                num={i + 1}
                                name={el}
                                isOrange={true}
                                lastItem={i === STEPS.length - 1}
                            />
                        );
                    })}
                </div>
            </div>
            <div className={styles.technologyList}>
                <TechnologyList
                    technologies={breakpoints.l ? TECHNOLOGIES.slice(0, 4) : TECHNOLOGIES}
                    keyPrefix="mobileTech"
                    isIncreasedSize={!breakpoints.l}
                />
            </div>
        </div>
    )
}