import classNames from 'classnames';
import React from 'react';
import * as styles from './StepItem.module.scss';


interface StepItemProps {
    num: number
    name: string
    isOrange?: boolean
    lastItem: boolean
    isVertical?: boolean
    className?: string
}

const StepItem: React.FC<StepItemProps> = ({ num, name, isOrange, lastItem, isVertical, className }) => {
    const roundClass = classNames(styles.leftRound, {
        [styles.orangeCircle]: isOrange,
    });

    const nameClass = classNames(styles.name, {
        [styles.orangeName]: isOrange,
        [styles.verticalName]: isVertical,
    });

    const dotClass = classNames(styles.dottedLine, {
        [styles.verticalDot]: isVertical,
        [styles.orangeDotted]: isOrange && !isVertical,
    });

    if (isVertical) {
        return (
            <div className={classNames(styles.stepBlock, className)}>
                <div className={styles.top}>
                    <div className={roundClass}>
                        <span className={styles.num}>{num}</span>
                    </div>
                    {!lastItem && <div className={dotClass}></div>}
                </div>
                <div className={styles.bot}>
                    <span className={nameClass}>{name}</span>
                </div>
            </div>
        );
    }

    return (
        <div className={classNames(styles.stepLine, className)}>
            <div className={styles.right}>
                <div className={roundClass}>
                    <span className={styles.num}>{num}</span>
                </div>
                {!lastItem && <div className={dotClass}></div>}
            </div>

            <div className={styles.left}>
                <span className={nameClass}>{name}</span>
            </div>
        </div>
    );
};

export default StepItem;
