// extracted by mini-css-extract-plugin
export var dottedLine = "StepItem-module--dottedLine--04c15";
export var left = "StepItem-module--left--ef30b";
export var leftRound = "StepItem-module--leftRound--763ff";
export var name = "StepItem-module--name--aeb3a";
export var num = "StepItem-module--num--87193";
export var orangeCircle = "StepItem-module--orangeCircle--665ec";
export var orangeDotted = "StepItem-module--orangeDotted--80b60";
export var orangeName = "StepItem-module--orangeName--a9245";
export var right = "StepItem-module--right--f6af2";
export var stepBlock = "StepItem-module--stepBlock--d1b88";
export var stepLine = "StepItem-module--stepLine--00a8b";
export var top = "StepItem-module--top--9ae0e";
export var verticalDot = "StepItem-module--verticalDot--80664";
export var verticalName = "StepItem-module--verticalName--1ffb8";