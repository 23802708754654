import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import clock from '../../../images/technologiesPage/clock.svg';
import StepItem from '../StepItem/StepItem';
import { TechnologyList } from '../TechnologiesList';
import * as styles from './Delivery.module.scss';

const STEPS = [
    'Integration with version control system',
    'Setting build system',
    'Saving the result in a regestry',
    'Running tests',
    'Counting metrics',
    'Stage deployment',
];

const TECHNOLOGIES = ['git', 'docker', 'yaml', 'vue', 'compose', 'jenkins2'];

const Delivery: React.FC = () => {
    const breakpoints = useBreakpoint()

    return (
        <div id="continous-delivery" className={styles.wrapper}>
            <div className={styles.top}>
                <img className={styles.browserClass} src={clock} alt="clock" />

                <span className={styles.topTitle}>Continious Delivery</span>
            </div>

            <div className={styles.mid}>
                {STEPS.map((el, i) => {
                    return (
                        <StepItem
                            key={'delivetyStep' + i}
                            isVertical={!breakpoints.l}
                            num={i + 1}
                            name={el}
                            lastItem={i === STEPS.length - 1}
                            isOrange={true}
                        />
                    );
                })}
            </div>

            <div className={styles.bot}>
                <TechnologyList
                    technologies={breakpoints.l ? TECHNOLOGIES.slice(0, 4): TECHNOLOGIES}
                    keyPrefix="deliveryTech"
                    isIncreasedSize={!breakpoints.l}
                />
            </div>
        </div>
    );
}

export default Delivery;
