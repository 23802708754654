import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import gear from '../../../images/technologiesPage/gear.svg';
import microservices from '../../../images/technologiesPage/microservices.svg';
import StepItem from '../StepItem/StepItem';
import { TechnologyList } from '../TechnologiesList';
import * as styles from './EmbeddedSystems.module.scss';

const STEPS = [
    'Define Business Boundaries',
    'Design high level architecture',
    'Choose technology stack',
    'Development with continious integration',
    'Deployment',
    'Runtime lifecycle management',
    'Logging and monitoring',
];

const TECHNOLOGIES = ['kubernetes', 'docker', 'greencloud', 't', 'nginx', 'openshift', 'istio', 'linkerd'];

const EmbeddedSystems: React.FC = () => {
    const breakpoints = useBreakpoint()

    return (
        <div id="embedded-systems" className={styles.wrapper}>
            <div className={styles.top}>
                <img className={styles.browserClass} src={gear} alt="gear" />
                <span className={styles.topTitle}>Embedded Systems</span>
            </div>
            <div className={styles.mid}>
                {!breakpoints.l && (
                    <div className={styles.midRight}>
                        <img className={styles.monitoringClass} src={microservices} alt="cloud" />
                    </div>
                )}
                <div className={styles.left}>
                    {STEPS.map((el, i) => {
                        return (
                            <StepItem
                                key={'microStep' + i}
                                num={i + 1}
                                name={el}
                                lastItem={i === STEPS.length - 1}
                            />
                        );
                    })}
                </div>
            </div>
            <div className={styles.bot}>
                <TechnologyList
                    technologies={breakpoints.l ? TECHNOLOGIES.slice(0, 4): TECHNOLOGIES}
                    keyPrefix="embeddedTech"
                    isIncreasedSize={false}
                />
            </div>
        </div>
    );
}

export default EmbeddedSystems;
