import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import design from '../../../images/technologiesPage/design.svg';
import desktop from '../../../images/technologiesPage/desktop.png';
import StepItem from '../StepItem/StepItem';
import { TechnologyList } from '../TechnologiesList';
import * as styles from './UX.module.scss';

const STEPS = [
    'User Requirements analysis',
    'Creation of user stories / scenario map / sitemap',
    'Wireframing',
    'Detailing the design of the interface',
    'Сreating an animated prototype',
    'Usability testing',
];

const TECHNOLOGIES = ['figma', 'rubin', 'baloon', 'proto', 'ps', 'stairs', 'axure', 'xd', 'in'];

const UX: React.FC = () => {
    const breakpoints = useBreakpoint()

    return (
        <div id="ux" className={styles.wrapper}>
            <div className={styles.top}>
                <img className={styles.browserClass} src={design} alt="design" />

                <span className={styles.topTitle}>UX/UI Design</span>
            </div>

            <div className={styles.mid}>
                <div className={styles.left}>
                    {STEPS.map((el, i) => {
                        return (
                            <StepItem key={'uxStep' + i} num={i + 1} name={el} lastItem={i === STEPS.length - 1} />
                        );
                    })}
                </div>

                <div className={styles.right}>
                    <TechnologyList
                        technologies={TECHNOLOGIES}
                        keyPrefix="uxTech"
                        isIncreasedSize={!breakpoints.l}
                    />
                </div>
            </div>

            {!breakpoints.l && (
                <div className={styles.bot}>
                    <img className={styles.desktop} src={desktop} alt="desktop" />
                </div>
            )}
        </div>
    );
}

export default UX;
