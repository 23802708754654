// extracted by mini-css-extract-plugin
export var bot = "Solutions-module--bot--34f67";
export var browserClass = "Solutions-module--browserClass--3b2eb";
export var headers = "Solutions-module--headers--51257";
export var mid = "Solutions-module--mid--cc115";
export var midLeft = "Solutions-module--midLeft--8f53e";
export var midRight = "Solutions-module--midRight--d5861";
export var monitoringBlock = "Solutions-module--monitoringBlock--e976d";
export var monitoringClass = "Solutions-module--monitoringClass--d60db";
export var technologies = "Solutions-module--technologies--eb85f";
export var technologiesText = "Solutions-module--technologiesText--0e243";
export var top = "Solutions-module--top--1f283";
export var topTitle = "Solutions-module--topTitle--d20c5";
export var violetBack = "Solutions-module--violetBack--cef7d";
export var wrapper = "Solutions-module--wrapper--7b28e";