import React from 'react';
import Cloud from './Cloud/Cloud';
import Delivery from './Delivery/Delivery';
import EmbeddedSystems from './EmbeddedSystems/EmbeddedSystems';
import { MachineLearning } from './MachineLearning/MachineLearning';
import Mobile from './Mobile/Mobile';
import Solutions from './Solutions/Solutions';
import UX from './UX/UX';
import Web from './Web/Web';

export const Technologies: React.FC = () => {
    return (
        <>
            <Solutions />
            <Cloud />
            <EmbeddedSystems />
            <Delivery />
            <UX />
            <Web />
            <Mobile />
            <MachineLearning />
        </>
    );
}
