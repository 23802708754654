import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import cloud from '../../../images/technologiesPage/cloud.svg';
import weather from '../../../images/technologiesPage/weather.svg';
import StepItem from '../StepItem/StepItem';
import Technology from '../Technology/Technology';
import * as styles from './Cloud.module.scss';

const STEPS = [
    'Cloud migration – moving off existing products to scalable cloud environment',
    'Design application as a collection of services',
    ' Development with continious integration',
    'Security system within the application',
    'Runtime lifecycle management',
    'Logging and monitoring',
];

const TECHNOLOGIES = [
    'jenkins',
    'azure',
    'aws',
    'etcd',
    'microsoft',
    'kubernetes',
    'kubernetes2',
    'softlayer',
    'zookeeper',
];

const Cloud: React.FC = () => {
    const breakpoints = useBreakpoint();

    return (
        <div id="cloud" className={styles.wrapper}>
            <div className={styles.top}>
                <img className={styles.browserClass} src={weather} alt="weather" />

                <span className={styles.topTitle}>Cloud Solutions</span>
            </div>
            <div className={styles.mid}>
                {!breakpoints.l && (
                    <div className={styles.midRight}>
                        <img className={styles.monitoringClass} src={cloud} alt="cloud" />
                    </div>
                )}
                <div className={styles.left}>
                    {STEPS.map((el, i) => {
                        return (
                            <StepItem
                                key={'cloudStep' + i}
                                num={i + 1}
                                name={el}
                                lastItem={i === STEPS.length - 1}
                                isOrange={true}
                            />
                        );
                    })}
                </div>
            </div>
            <div className={styles.bot}>
                {TECHNOLOGIES.map((el, i) => {
                    if (breakpoints.l && i > 4) {
                        return undefined;
                    }
                    return <Technology key={'cloudTech' + i} technology={el} increasedSize={false} />;
                })}
            </div>
        </div>
    );
}

export default Cloud;
